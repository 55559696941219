<template>
    <div class="expressJoinWarp">
        <div class="title"><i></i> {{formData.name}}</div>
        <ul class="task" v-if="formData.child">
            <li v-for="(item , index) in formData.child" :key="index">
                <div  v-if="item.child" class="item">
                    <div v-if="item.taskName" class="title"><i></i> {{item.taskName}}</div>
                    <div v-for="(inputItem,pindex) in item.child" :key="pindex">
                        <div v-if="inputItem.taskCode === 'idCard'">
                            <van-field clickable
                                       v-model="inputItem.taskValue"
                                       :maxlength="inputItem.inputCount"
                                       :minlength="inputItem.minInputCount"
                                       :label="inputItem.taskName"
                                       placeholder="请输入身份证号"
                                       @click-right-icon.stop="rightChooseImg(index,pindex)"
                                       right-icon="photograph"></van-field>
                            <van-uploader :preview-image="false"
                                          ref="rightCameraImg"
                                          accept="image"
                                          style="display:none;"
                                          v-model="fileList"
                                          :after-read="afterReadIdCard" />

                        </div>

                        <van-field :label="inputItem.taskName" v-model="inputItem.taskValue"
                                    :maxlength="inputItem.inputCount"
                                    :minlength="inputItem.minInputCount"
                                   :placeholder="inputItem.taskDesc"
                                   v-else-if="inputItem.taskType === 'input_text'"/>
                        <van-field :label="inputItem.taskName"
                                   v-model="inputItem.taskValue"
                                   :maxlength="inputItem.inputCount"
                                   :minlength="inputItem.minInputCount"
                                   :placeholder="inputItem.taskDesc"
                                   clearable @blur="telVerify(inputItem.taskValue)"
                                   type="tel"
                                   v-else-if="inputItem.taskType === 'input_phone'"/>


                        <van-field clickable v-else-if="inputItem.taskType === 'selectBox'"
                                   readonly
                                   v-model="inputItem.taskValue"
                                   :label="inputItem.taskName"
                                   placeholder="请选择"
                                   @click="clickProp(inputItem.taskName,JSON.parse(inputItem.alternative),index,pindex)"
                                   right-icon="arrow"></van-field>
                        <div class="radioDiv" v-else-if="inputItem.taskType === 'alter_bool'">
                            <div class="title van-cell__title "><i></i>{{inputItem.taskName}}</div>
                            <van-radio-group v-model="inputItem.taskValue">
                                <van-radio :key="rindex" v-for="(rItem,rindex) in JSON.parse(inputItem.alternative) " :name="rItem">{{rItem}}</van-radio>

                            </van-radio-group>

                        </div>


                        <van-field clickable
                                   readonly
                                   v-if="inputItem.taskCode === 'presentArea'"
                                   v-model="presentArea"
                                   label="省市区县"
                                   placeholder="省份、城市、区县"
                                   @click="cityClick(inputItem.taskCode,index,pindex)"
                                   right-icon="arrow"></van-field>
                        <van-field clickable
                                   readonly
                                   v-if="inputItem.taskCode === 'domicileArea'"
                                   v-model="domicileArea"
                                   label="省市区县"
                                   placeholder="省份、城市、区县"
                                   @click="cityClick(inputItem.taskCode,index,pindex)"
                                   right-icon="arrow"></van-field>
                        <van-field clickable
                                   readonly
                                   v-if="inputItem.taskCode === 'joinArea'"
                                   v-model="joinArea"
                                   label="省市区县"
                                   placeholder="省份、城市、区县"
                                   @click="cityClick(inputItem.taskCode,index,pindex)"
                                   right-icon="arrow"></van-field>
                        <div v-if="inputItem.taskType === 'pic'"></div>
                    </div>
                    <div class="gray10"></div>
                </div>

                <div v-else style="width: 100%;">

                    <van-field :label="item.taskName"
                               v-model="item.taskValue"
                               :maxlength="item.inputCount"
                               :placeholder="item.taskDesc" v-if="item.taskType === 'input_text'"/>
                    <van-field :label="item.taskName"
                               :maxlength="item.inputCount"
                               v-model="item.taskValue"
                               :placeholder="item.taskDesc"
                               clearable @blur="telVerify(item.taskValue)"
                               type="tel"
                               v-else-if="item.taskType === 'input_phone'"/>
                    <van-field clickable v-else-if="item.taskType === 'selectBox'"
                               readonly
                               v-model="item.taskValue"
                               :label="item.taskName"
                               placeholder="请选择"
                               @click="clickProp(item.taskName,JSON.parse(item.alternative),index)"
                               right-icon="arrow"></van-field>

                    <div class="radioDiv" v-else-if="item.taskType === 'alter_bool'">
                        <div  class="title van-cell__title"><i></i>{{item.taskName}}</div>
                        <van-radio-group v-model="item.taskValue" checked-color="#3F7C53">
                            <van-radio :key="rindex" v-for="(rItem,rindex) in JSON.parse(item.alternative) " :name="rItem">{{rItem}}</van-radio>

                        </van-radio-group>

                    </div>

                    <div class="radioDiv" v-if="item.taskType === 'multipleChoice'" >
                        <div class="title"><i></i> {{item.taskName}}</div>
                        <van-checkbox-group v-model="checkboxArr" >
                            <van-checkbox v-for="(rItem,rindex) in JSON.parse(item.alternative) "
                                          :key="rindex"
                                          checked-color="#3F7C53"
                                          @click="multipleChoiceChange(checkboxArr,index)"
                                          :name="rItem"
                            >{{rItem}}</van-checkbox>

                        </van-checkbox-group>
                    </div>
                    <van-field clickable
                               readonly
                               v-if="item.taskCode === 'presentArea'"
                               v-model="presentArea"
                               label="省市区县"
                               placeholder="省份、城市、区县"
                               @click="cityClick(item.taskCode,index,pindex)"
                               right-icon="arrow"></van-field>
                    <van-field clickable
                               readonly
                               v-if="item.taskCode === 'domicileArea'"
                               v-model="domicileArea"
                               label="省市区县"
                               placeholder="省份、城市、区县"
                               @click="cityClick(item.taskCode,index,pindex)"
                               right-icon="arrow"></van-field>
                    <van-field clickable
                               readonly
                               v-if="item.taskCode === 'joinArea'"
                               v-model="joinArea"
                               label="省市区县"
                               placeholder="省份、城市、区县"
                               @click="cityClick(item.taskCode,index,pindex)"
                               right-icon="arrow"></van-field>

                    <div v-if="item.taskType === 'pic'">
                        <div class="card-upload">
                            <van-uploader v-model="fileList"
                                          accept="image"
                                          :max-count="item.inputCount"
                                          :after-read="afterRead"
                                          :before-delete="deletePic" />
                        </div>
                    </div>
                    <div class="gray10"></div>
                </div>


            </li>
        </ul>

        <div class="eJCommitBtnDiV">
            <div class="eJCommitBtn" @click="commitAction">
                提交
            </div>
        </div>
        <van-popup v-model="propShow"
                   position="bottom">
            <van-picker show-toolbar
                        :title="pickerTitle"
                        :columns="columns"
                        @cancel="propShow = false"
                        @confirm="propComfirm" />
        </van-popup>
        <van-popup v-model="addressShow"
                   position="bottom">
            <van-area :area-list="areaList"
                      @cancel="addressShow = false"
                      @confirm="areaConfirm"></van-area>
        </van-popup>
    </div>
</template>

<script>
    import areaList from "@/utils/area"
    import common from '@/utils/common'
    import {getSubExpressDetail, idCardOcrFront, saveSubExpress, uploadZipAndAlreadyImg} from "../../utils/api";
    import JsZip from "jszip";
    import {uwStatisticAction} from "../../utils/util";

    export default {
        name: "expressJoin",
        data(){
            return{
                fileList: [],
                serveImgList:[],
                taskList:[{"taskName":"1、申请人基本信息","id":1,"hasFinished":1},
            {"taskName":"2、申请站点信息","id":2,"hasFinished":0},
            {"taskName":"3、站点门头照","id":3,"hasFinished":0}],
                formData:[],
                columns:[],
                propShow:false,
                option1: [
                    { text: '全部商品', value: '男' },
                    { text: '新款商品', value: 1 },
                    { text: '活动商品', value: 2 },
                ],
                testArr:["1","2","3"],
                checkboxArr:["1"],
                uploadZipImgList: [],
                savePara:{
                    applyNo:'',
                    id:'',
                    taskDetail:'',
                    joinArea:'',
                    userName:'',
                    phoneNo:''
                },
                paraIndex:-1,
                subIndex:-1,
                addressShow:false,
                areaList,
                presentArea:'',
                domicileArea:'',
                joinArea:'',
                areaKey:'',
                pickerTitle:'',
                fileImgUrls:[]
            }
        },
        mounted() {
            let p = {
                id :this.$route.query.id
            }
            getSubExpressDetail(p).then(res=>{
                if (res.code === 200 && res.data.businessCode === 200){
                    this.formData = res.data.data
                    this.formData.child.forEach((item,index)=>{
                        if (item.taskType === 'multipleChoice'){
                            this.checkboxArr = JSON.parse( item.taskValue)

                        }
                        if (item.taskCode === 'presentArea'){
                            this.presentArea = JSON.parse( item.taskValue)

                        }
                        if (item.taskCode === 'joinArea'){
                            this.joinArea = JSON.parse( item.taskValue)

                        }
                        if (item.taskCode === 'domicileArea'){
                            this.domicileArea = JSON.parse( item.taskValue)

                        }
                        if (item.child){

                            item.child.forEach((inputItem,inputIndex)=>{

                                if (inputItem.taskCode === 'presentArea'){
                                    this.presentArea = inputItem.taskValue

                                }
                                if (inputItem.taskCode === 'joinArea'){
                                    this.joinArea = inputItem.taskValue

                                }
                                if (inputItem.taskCode === 'domicileArea'){
                                    this.domicileArea = inputItem.taskValue

                                }
                            })
                        }
                        if(item.taskType == 'pic' && this.formData.child[0].taskValue){
                            var baseUrl = process.env.NODE_ENV != 'production' ? 'http://124.70.167.116:20081' + '/file/' : window.location.origin + '/file/'
                            var tokenUrl = '?Authorization=' + common.getItem('wtToken')

                            for(let obj of JSON.parse(this.formData.child[0].taskValue)  ){
                                this.fileList.push({
                                    'url' : baseUrl + obj + tokenUrl
                                })
                                this.serveImgList .push(obj)
                            }

                        }
                    })

                }
            })
            uwStatisticAction('/expressJoinItem','0元加盟村口快递子任务内容填写')
        },
        filters:{
            namevalue(){

            }

        },
        methods:{
            afterRead (file, detail) {

                // 大于15M
                if (file.file.size > 15 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于15M的图片')
                    return
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 100 * 1024) {

                    let namearr = file.file.name.split('.')

                    let name = '.' + namearr[namearr.length - 1]

                    _this.uploadZipImgList.push({ data: file.content, name: name })
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)

                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {

                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList.push({ data: dataURL, name: name })

                        })
                    }
                }
            },
            deletePic (file, pic) {

                let arr = this.formData.child[0].taskValue ?  JSON.parse(this.formData.child[0].taskValue) : []

                arr.splice(pic.index,1)
                this.fileList.splice(pic.index, 1)
                if(file.url){
                  this.serveImgList.splice(pic.index,1)
                }else{
                  this.uploadZipImgList.splice(pic.index - this.serveImgList.length,1)
                }
                if (arr.length>0){
                  this.formData.child[0].taskValue = JSON.stringify(arr)
                  this.formData.child[0].hasFinished = true

                }else {
                  this.formData.child[0].taskValue = ''
                  this.formData.child[0].hasFinished = false
                }
            },
            clickProp(title,columns,index,subIndex){
                this.pickerTitle = title
                if (subIndex>-1){
                    this.subIndex = subIndex
                }else {
                    this.subIndex = -1
                }
                if (index>-1){
                    this.paraIndex = index
                }else {
                    this.paraIndex = -1
                }

                this.columns = columns
                this.propShow = true

            },
            propComfirm(val){

                if (this.paraIndex>-1 && this.subIndex>-1){
                    this.formData.child[this.paraIndex].child[this.subIndex].hasFinished = true
                    this.formData.child[this.paraIndex].child[this.subIndex].taskValue = val

                }else if (this.paraIndex>-1 ){
                    this.formData.child[this.paraIndex].hasFinished = true
                    this.formData.child[this.paraIndex].taskValue = val
                }
                console.log("参数：",this.formData)

                this.propShow = false;

            },
            telVerify(val) {
                common.kickBack();
                return  common.phoneVerify(val.replace(/\s+/g,""))
            },

            areaConfirm (val) {
                let addressVal = ''
                this.addressShow = false

                for (let obj of val) {
                    addressVal += obj.name + ' '

                }
                if (this.paraIndex>-1 && this.subIndex>-1){
                    this.formData.child[this.paraIndex].child[this.subIndex].hasFinished = true
                    this.formData.child[this.paraIndex].child[this.subIndex].taskValue =addressVal

                }else if (this.paraIndex>-1 ){
                    this.formData.child[this.paraIndex].hasFinished = true
                    this.formData.child[this.paraIndex].taskValue = addressVal
                }
                if (this.areaKey === 'domicileArea'){
                    this.domicileArea = addressVal
                }else if (this.areaKey === 'joinArea'){
                    this.joinArea = addressVal
                }else if (this.areaKey === 'presentArea'){
                    this.presentArea = addressVal
                }



            },
            cityClick(areaKey,index,subIndex){
                this.areaKey = areaKey
                if (subIndex>-1){
                    this.subIndex = subIndex
                }else {
                    this.subIndex = -1
                }
                if (index>-1){
                    this.paraIndex = index
                }else {
                    this.paraIndex = -1
                }

                this.addressShow = true
            },
            commitAction(){
                if(this.formData.child[0].taskType == 'pic' && this.uploadZipImgList.length !=0) {
                    let self = this
                    let imgName = this.formData.child[0].taskCode
                    var zip = new JsZip();
                    var img = zip.folder("images");
                    for (var i = 0; i < this.uploadZipImgList.length; i++) {
                        img.file(imgName + '_' + i + this.uploadZipImgList[i].name, this.uploadZipImgList[i].data.substring(this.uploadZipImgList[i].data.indexOf(",") + 1), {base64: true});
                    }
                    let json = {imgName: self.fileImgUrls}
                    let fileAleradyJson = JSON.stringify(json)
                    zip.generateAsync({type: "blob"}).then(function (content) {

                        uploadZipAndAlreadyImg(content, self.stationNo, fileAleradyJson).then(res => {
                            if (res.code == 200 && (res.data.businessCode === 200)) {

                                let arr = self.serveImgList.concat(res.data.storefrontSignboard)

                                self.formData.child[0].taskValue = JSON.stringify(arr)
                                self.formData.child[0].hasFinished = true
                                self.saveAction()

                            }
                        }).catch(() => {
                        })

                    }, e => {
                        this.$toast('上传图片异常')

                    });

                }else {
                    this.saveAction()

                }


            },
            saveAction(){
                let finished = true
                for (var i = 0;i<this.formData.child.length ;i++){
                    if (this.formData.child[i].taskType === 'multipleChoice'){

                        this.formData.child[i].taskValue = JSON.stringify(this.checkboxArr)

                    }

                    if (this.formData.child[i].taskType === 'group' ){
                        for (var j = 0;j<this.formData.child[i].child.length;j++){
                            if (this.formData.child[i].child[j].taskCode === 'idCard'){

                                if (!common.IdCardVerify(this.formData.child[i].child[j].taskValue)){
                                    console.log("身份证错误")
                                    finished = false
                                    this.$toast(this.formData.child[i].child[j].taskDesc)
                                    break
                                }else {
                                    this.formData.child[i].child[j].hasFinished = true
                                }


                            }
                           else if (this.formData.child[i].child[j].taskCode === 'phoneNo'){

                                if (!common.PhoneCheck(this.formData.child[i].child[j].taskValue)){
                                    finished = false
                                    this.$toast(this.formData.child[i].child[j].taskDesc)
                                    break
                                }else {
                                    this.formData.child[i].child[j].hasFinished = true
                                }


                            }
                           else if (!this.formData.child[i].child[j].taskValue || this.formData.child[i].child[j].taskValue.length<this.formData.child[i].child[j].minInputCount){
                                this.$toast(this.formData.child[i].child[j].taskDesc)
                                finished = false
                                break
                            }else {
                                this.formData.child[i].child[j].hasFinished = true
                            }
                            for (var pI in this.savePara){

                                if (this.formData.child[i].child[j].taskCode === pI){
                                    this.savePara[pI] = this.formData.child[i].child[j].taskValue

                                }
                            }

                        }
                        if (!finished){
                            break
                        }


                    }
                    else {

                        if (!this.formData.child[i].taskValue){
                            this.$toast(this.formData.child[i].taskDesc)

                            finished = false
                            break
                        }else {
                            this.formData.child[i].hasFinished = true
                        }
                    }
                }

                this.savePara.applyNo = this.$route.query.applyNo
                this.savePara.id = this.$route.query.id
                this.savePara.taskDetail = JSON.stringify(this.formData)
                console.log("提交：",this.formData)
                if (finished){
                    saveSubExpress(this.savePara).then(res=>{

                        if (res.code === 200 && res.data.businessCode === 200){
                            this.$router.go(-1)
                        }
                    })
                }

            },
            rightChooseImg (paraIndex,subIndex) {
                this.paraIndex = paraIndex
                this.subIndex = subIndex
                this.$refs.rightCameraImg[0].chooseFile()
            },
            afterReadIdCard (file) {
                console.log(file)
                // 大于15M
                if (file.file.size > 15 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于15M的图片')
                    return
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                if (file.file.size < 100 * 1024) {
                    this.saveFile(file.content)
                    return
                }
                this.filename = file.file.name
                    ? file.file.name
                    : "defaultname";
                let reader = new FileReader()
                reader.readAsDataURL(file.file)
                let _this = this
                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    img.src = content
                    img.onload = function () {
                        common.compress(img, (dataURL) => {
                            _this.saveFile(dataURL)
                        })
                    }
                }
            },
            saveFile (dataURL) {
                let params = {
                    fileContent: dataURL
                }
                idCardOcrFront(params).then(res => {
                    if (res.code === 200) {
                        if (res.data.businessCode === 200) {
                            if (this.paraIndex>-1 && this.subIndex>-1){
                                this.formData.child[this.paraIndex].child[this.subIndex].hasFinished = true
                                this.formData.child[this.paraIndex].child[this.subIndex].taskValue =res.data.idCard

                            }else if (this.paraIndex>-1 ){
                                this.formData.child[this.paraIndex].hasFinished = true
                                this.formData.child[this.paraIndex].taskValue = res.data.idCard
                            }
                        }
                    }
                })
            },
            multipleChoiceChange(val,){
                if (val[val.length -1 ] === '无'){

                    this.checkboxArr = ['无']
                }else {
                    let index = this.checkboxArr.indexOf('无')
                    if (index > -1){
                        this.checkboxArr.splice(index,1)
                    }

                }

            }
        }

    }
</script>

<style scoped lang="less">
    .expressJoinWarp{
        padding: 12px 0;
        background: #f7f8f9;
        min-height: 100%;
        .title{
            background: #ffffff;
            font-size: 17px;
            font-family: PingFangSC-Medium, PingFang SC;
            color: #333333;
            padding:  13px 15px;
            font-weight: bold;
            i{
                display: inline-block;
                width: 2px;
                height: 15px;
                background: #3F7C53;
                border-radius: 1px;
                margin-right: 4px;
            }

        }

        .task{
            background: #ffffff;
            padding-bottom: 60px;
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding:0 ;
                border-bottom: 1px solid #f7f8f9;
                .item{
                    width: 100%;
                    padding:  0;

                }
                .taskName{
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
                .notFinish{
                    background: #3F7C53;
                    border-radius: 13px;
                    padding: 0 12px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    height: 25px;
                    line-height: 25px;
                }
                .finished{

                    background: #FFFFFF;
                    border-radius: 13px;
                    padding: 0 12px;
                    border: 1px solid #3F7C53;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #3F7C53;
                    height: 25px;
                    line-height: 25px;

                }
            }
            .van-swipe-item{
                width: 100% !important;
            }
        }
        .van-field__control{
            text-align: right;
        }
        .gray10{
            height: 10px;
            width: 100%;
            background: #f7f8f9;
        }
        .radioDiv{
            padding: 15px;
            .title{
                padding: 0;
                margin-bottom: 8px;

            }
            .van-radio{
                padding: 11px  0;
                border-bottom: 1px solid #f7f8f9;
            }
            .van-checkbox{
                padding: 11px  0;
                border-bottom: 1px solid #f7f8f9;
            }
        }
        .card-upload{
            padding: 15px;
        }

    }


</style>
<style lang="less">
    .eJCommitBtnDiV{
        position: absolute;
        z-index: 100;
        bottom:0;
        left: 0;
        width: 100%;
        background: #ffffff;
        padding:15px ;
    }
    .eJCommitBtn{

        width: 100%;

        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        height: 49px;
        line-height: 49px;
        border-radius: 4px;
        background: #3F7C53;
        opacity: 1;
        text-align: center;

    }
    .eJCommitBtnActive{

        background: #3F7C53;
        opacity: 1;

    }
   .expressJoinWarp .van-cell,.expressJoinWarp .van-field{
       padding: 14px 15px;
       font-size: 15px;
       font-family: PingFangSC-Regular, PingFang SC;
   }
    .expressJoinWarp .van-field__control{
        text-align: right;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;

    }
    .expressJoinWarp .van-cell__title, .expressJoinWarp .van-field__label{
        width: 140px;

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .expressJoinWarp .van-radio__label{
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .expressJoinWarp  input::-webkit-input-placeholder{

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .expressJoinWarp input::-moz-placeholder{   /* Mozilla Firefox 19+ */

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .expressJoinWarp input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    .expressJoinWarp  input:-ms-input-placeholder{  /* Internet Explorer 10-11 */

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
</style>